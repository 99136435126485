import { useEffect, useState } from 'react';
import { defaultTheme } from '@styles/theme';

const useScrolled = (): [boolean] => {
  const [isScrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const { scrollY } = window;
    const headerHeight = parseInt(defaultTheme.headerHeight.slice(0, 2), 10);

    if (scrollY <= headerHeight) {
      return setScrolled(false);
    }
    return setScrolled(true);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll, {
        passive: true,
        capture: true,
      });
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  return [isScrolled];
};

export default useScrolled;
