import styled from 'styled-components';

export const PageWrap = styled.div<{ isWhite?: boolean }>`
  ${({ theme }) => theme.layout.width};
  min-height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  background: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.white : theme.colors.gray100};
  color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.gray100 : theme.colors.white};
  overscroll-behavior: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  &:has(header) {
    padding-top: ${({ theme }) => theme.headerHeight};
  }

  &:has(#main) {
    height: 100%;
    background-image: url('/images/main-background.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:has(#bottom-nav) {
    padding-bottom: ${({ theme }) => theme.bottomNavHeight};
  }

  &:has(#mobile-bottom-nav) {
    padding-bottom: ${({ theme }) => theme.mobileBottomNavHeight};
  }
`;

export const TermsPageWrap = styled.div`
  ${({ theme }) => theme.font.pretendard};
  ${({ theme }) => theme.font.body3};
  display: flex;
  flex-direction: column;
  padding: 20px;

  td {
    border: 1px solid black;
    padding: 4px;
    vertical-align: middle;
  }

  thead td {
    background: ${({ theme }) => theme.colors.gray10};
  }

  p {
    word-break: break-all;
  }

  strong {
    font-weight: 600;
  }
`;

export const FixedHeaderRightButtonWrap = styled.div`
  ${({ theme }) => theme.headerSize};
  pointer-events: none;

  > div,
  > button {
    pointer-events: auto;
    position: fixed;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;
