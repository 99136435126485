import styled from 'styled-components';

export const HeaderWrap = styled.header<{
  $style?: 'white' | 'black' | 'transparent';
}>`
  ${({ theme, $style }) => theme.headerColor[$style || 'white']};
  width: 100%;
  height: ${({ theme }) => theme.headerHeight};
  min-height: ${({ theme }) => theme.headerHeight};
  z-index: ${({ theme }) => theme.zIndex.header};
  position: fixed;
  top: 0;
  left: 0;

  > div.header-content {
    ${({ theme }) => theme.headerSize};
    display: flex;
    align-items: center;
  }

  svg {
    cursor: pointer;
  }
`;

export const CenterTitleWrap = styled.h1`
  ${({ theme }) => theme.font.body1};
  font-weight: ${({ theme }) => theme.fw.semiBold};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
`;

export const LeftTitleWrap = styled.h1`
  ${({ theme }) => theme.font.title3};
  font-weight: ${({ theme }) => theme.fw.semiBold};
  color: inherit;
`;

export const BackButtonWrap = styled.button`
  background: none;
  width: 24px;
  height: 24px;
  padding: 0;
  color: inherit;
`;

export const RightButtonContainerWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 8px 0 8px 8px;
`;
