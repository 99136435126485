import styled from 'styled-components';

export const PinkToolTipWrap = styled.div<{ top: string }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  top: ${({ top }) => top};
  transform: translateX(-50%);
`;

export const PinkTooltipContentWrap = styled.div<{
  arrowDirection: 'top' | 'bottom';
}>`
  ${({ theme }) => theme.font.hGGGothicssi};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.pink20};
  padding: 4px 10px;
  ${({ theme, arrowDirection }) =>
    arrowDirection === 'bottom'
      ? `
  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${theme.colors.pink20} transparent
      transparent transparent;
  }
  `
      : `
   &::after {
    content: ' ';
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent
      ${theme.colors.pink20} transparent;
  }
  `}
`;

export const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  span {
    ${({ theme }) => theme.font.body3};
    color: ${({ theme }) => theme.colors.white};
  }
`;
