import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { PageWrap } from '@styles/common-layouts';
import BottomNav from '@components/layout/BottomNav';
import MobileBottomNav from '@components/layout/MobileBottomNav';
import Header, { HeaderProps } from '@components/layout/Header';
import useDeviceStore from '@hooks/useDeviceStore';
import useScrolled from '@hooks/useScrolled';
import { layoutMountAtom } from '@src/stores/mount';
import { defaultTheme } from '@styles/theme';

interface Props extends HeaderProps {
  isWhite?: boolean;
  nav?: boolean;
  children: React.ReactNode;
}

const NavBar = ({ isApp }: { isApp: boolean }) => {
  return isApp ? <MobileBottomNav /> : <BottomNav />;
};

/* eslint-disable react/destructuring-assignment */
const PageLayout = ({ nav = true, isWhite = false, ...props }: Props) => {
  const [isScrolled] = useScrolled();
  const [isMount, setIsMount] = useAtom(layoutMountAtom);

  const [state] = useDeviceStore();
  const [appData, setAppData] = useState({
    isApp: !!state?.deviceToken,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setAppData({
        isApp: !!(state?.deviceToken || ''),
      });
    }
  }, [state?.deviceToken]);

  useEffect(() => {
    setIsMount(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (document) {
      if (isWhite) {
        document.body.style.backgroundColor = defaultTheme.colors.white;
      } else {
        document.body.style.backgroundColor = defaultTheme.colors.gray100;
      }
    }
  }, [isWhite]);

  const getHeaderStyle = () => {
    if (props.$style === 'transparent') {
      if (isScrolled) {
        return 'black';
      }
      return props.$style;
    }
    return props.$style;
  };

  return (
    <PageWrap isWhite={isWhite}>
      <Header
        pageTitle={props?.pageTitle}
        titleAlign={props?.titleAlign}
        routerCallback={props?.routerCallback}
        hideBackButton={props?.hideBackButton}
        headerRightButton={props?.headerRightButton || undefined}
        customHeaderStyle={{ ...props?.customHeaderStyle }}
        $style={getHeaderStyle()}
      />
      {props.children}
      {nav && isMount && <NavBar isApp={appData.isApp} />}
    </PageWrap>
  );
};

export default PageLayout;
