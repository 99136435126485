import styled from 'styled-components';

export const BottomNavWrap = styled.nav`
  ${({ theme }) => theme.layout.width};
  ${({ theme }) => theme.bottomNav};
  height: ${({ theme }) => theme.bottomNavHeight};
  z-index: ${({ theme }) => theme.zIndex.bottomNav};
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const BottomNavButton = styled.button<{ active: boolean }>`
  flex: 1 1 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  color: ${({ theme, active }) =>
    active ? theme.colors.gray10 : theme.colors.gray50};

  span {
    ${({ theme }) => theme.font.body5};
    font-size: 11px;
  }
`;
