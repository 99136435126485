import * as React from 'react';
import { CSSProperties } from 'react';
import { useAtomValue } from 'jotai';
import useNavigator from '@hooks/useAppRouter';
import BackIcon from '@icons/header/back.svg';
import { pathAtom } from '@src/stores/path';
import {
  BackButtonWrap,
  CenterTitleWrap,
  HeaderWrap,
  LeftTitleWrap,
  RightButtonContainerWrap,
} from '@components/layout/styles/header.style';

export interface HeaderProps {
  pageTitle?: string | React.ReactElement;
  titleAlign?: 'left' | 'center';
  routerCallback?: () => void;
  hideBackButton?: boolean;
  headerRightButton?: JSX.Element;
  customHeaderStyle?: CSSProperties;
  $style?: 'white' | 'black' | 'transparent';
}

const Header: React.FC<HeaderProps> = ({
  pageTitle,
  titleAlign = 'center',
  routerCallback,
  hideBackButton,
  headerRightButton,
  customHeaderStyle,
  $style = 'white',
}) => {
  const router = useNavigator();
  const path = useAtomValue(pathAtom);

  const handleClickBack = () => {
    if (routerCallback) {
      routerCallback();
    } else if (!path.prevPath) {
      router.push('/');
    } else {
      router.back();
    }
  };

  return (
    <HeaderWrap $style={$style} style={customHeaderStyle}>
      <div className="header-content">
        {!hideBackButton && titleAlign !== 'left' && (
          <BackButtonWrap onClick={handleClickBack} data-testid="back-button">
            <BackIcon />
          </BackButtonWrap>
        )}
        {titleAlign === 'left' && <LeftTitleWrap>{pageTitle}</LeftTitleWrap>}
        {titleAlign === 'center' && (
          <CenterTitleWrap>{pageTitle}</CenterTitleWrap>
        )}
        {headerRightButton && (
          <RightButtonContainerWrap id="header-right-btn">
            {headerRightButton}
          </RightButtonContainerWrap>
        )}
      </div>
    </HeaderWrap>
  );
};

export default Header;
