/* K 단위로 변환 */
export const formatNumberWithK = (num?: number): string => {
  if (!num) {
    return '-';
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num.toString();
};
