import * as React from 'react';
import Pick from '@icons/tooltip/ic_pick.svg';
import FinalWin from '@icons/tooltip/ic_FinalWin.svg';
import { formatNumberWithK } from '@utils/numberUtils';
import {
  ItemWrap,
  PinkTooltipContentWrap,
  PinkToolTipWrap,
} from '@components/common/styles/pinkTooltip.style';

const ICONS = {
  Pick,
  FinalWin,
};

interface Props {
  children: React.ReactNode;
  top: string;
  arrowDirection?: 'top' | 'bottom';
}

const PinkTooltip = ({
  top,
  arrowDirection = 'bottom',
  children,
}: Props): JSX.Element => {
  return (
    <PinkToolTipWrap top={top}>
      <PinkTooltipContentWrap
        style={{ display: 'flex', columnGap: '6px' }}
        arrowDirection={arrowDirection}
      >
        {children}
      </PinkTooltipContentWrap>
    </PinkToolTipWrap>
  );
};

interface Item {
  type: 'Pick' | 'FinalWin';
  count: number;
}

PinkTooltip.Item = ({ type, count }: Item): JSX.Element => {
  const Icon = ICONS[type];
  return (
    <ItemWrap>
      <Icon />
      <span>{formatNumberWithK(count)}</span>
    </ItemWrap>
  );
};
export default PinkTooltip;
